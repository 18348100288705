$nunitoSans: "NunitoSans";
$raleway: "Raleway";
$roboto: "Roboto";
$notoSansTc: 'Noto Sans TC', sans-serif;

$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

:root {
  --ion-color-pink: #ED1E79;
  --ion-color-pink-rgb: 237,30,121;
  --ion-color-pink-contrast: #ffffff;
  --ion-color-pink-contrast-rgb: 255,255,255;
  --ion-color-pink-shade: #d11a6a;
  --ion-color-pink-tint: #ef3586;
}

.ion-color-pink {
  --ion-color-base: var(--ion-color-pink);
  --ion-color-base-rgb: var(--ion-color-pink-rgb);
  --ion-color-contrast: var(--ion-color-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink-shade);
  --ion-color-tint: var(--ion-color-pink-tint);
}

$primary-color: #eb1b76;
$primary-color-darken: #c21260;
$black: #4a4a4a;
$red: #e02b27;
$pink: #ED1E79;
$blue: #4e92e2;

$clubpoint-background-color: #09417A;
$cp-navy-blue-filter: invert(11%) sepia(90%) saturate(5543%) hue-rotate(200deg) brightness(87%) contrast(101%);
$cp-grey-filter: invert(65%) sepia(2%) saturate(5%) hue-rotate(48deg) brightness(96%) contrast(84%);
$cp-white-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(270deg) brightness(113%) contrast(101%);
$content-background-color: #f8f8f8;

/* CSR */
$csr-navy-blue: #0B3066;
$csr-lightblue: #194583;
$csr-quickblue: #36AFE5;
$csr-pink: #E60066;
$csr-lightpink: #F56FAA;
$csr-bg-pink: #FEF3F4;
$csr-modal-border: $pink;

$csr-skew-deg: -15deg;
$csr-mobile-size: 576px;

.rc-slider {
  width: calc(100% - 50px)!important;
}