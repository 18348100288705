@import "../../styles/constants";

.input {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  position: relative;
}

.inputError {
  padding-right: 52px;
}

.titleColumn {
  font-size: 14px;
  line-height: 21px;
  font-family: $nunitoSans;
  font-weight: $font-weight-normal;
  color: #9b9b9b;

  .required {
    &:after {
      content: "*";
      color: $red;
      font-size: 12px;
      margin-left: 5px;
    }
  }
}

.inputColumn {
  flex: 1;
  font-size: 14px;
  font-family: $nunitoSans;
  font-weight: $font-weight-normal;
  color: #4a4a4a;
  letter-spacing: 0.2px;
}

.inputBox {
  background-color: transparent;
  border: 0;
  width: 100%;
  padding: 0;

  &::placeholder {
    color: #CCC;
  }
}

.clear {
  background-image: url(../../resources/cross-icon-grey.svg);
  background-color: transparent;
  background-size: 9px;
  background-position: center;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.successIcon {
  background-image: url(../../resources/validate-icon.svg);
  background-size: 15px;
  background-position: center;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 34px;
  top: 50%;
  transform: translateY(-50%);
}

.errorIcon {
  background-image: url(../../resources/cross-icon-white-red-bg-rounded.svg);
  background-size: 15px;
  background-position: center;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 34px;
  top: 50%;
  transform: translateY(-50%);
}

.errorMessage {
  font-size: 14px;
  font-family: $roboto;
  color: #e15a5a;
  margin-top: 3px;
}
