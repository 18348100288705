@import "../../styles/constants";

.cart-btn {
  position: relative;
  float: right;
  &__count {
    position: absolute;
    bottom: -0.2em;
    right: -0.2em;
  }
}