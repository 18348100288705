$transition-time: 0.25s;

@mixin touchableOpacityBackground($bg-active, $bg: transparent) {
  background-color: $bg;
  transition: background-color 0s $transition-time;

  &:active {
    background-color: $bg-active;
    transition: initial;
  }
}

@mixin touchableOpacity {
  // FML (YinYin)
  // If originally opacity is 1 and we modify the opacity value later, for example
  // when user touch the element while scrolling, the bounce of the scroll
  // will be jumpy. Therefore, we need to set opacity value to 0.99, which is
  // very near to 1
  opacity: 0.99;
  transition: opacity $transition-time;

  &:active {
    opacity: 0.6;
  }
}
