.ourTabs {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 100%;
  contain: layout size style;
  overflow: visible;
}

.ourTabs > ion-router-outlet {
  position: relative;
  flex: 1;
  contain: layout size style;
  overflow: visible;
}

.ourTabs > ion-router-outlet > .ion-page {
  overflow: visible;
}

.ourTabs > ion-tab-bar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
}

.hideTabBar {
  height: 100vh;
}
