@import "../../styles/constants";

:global(ion-tab-bar) {
  --background: black;

  :global(ion-label) {
    opacity: 0.5;
    color: white;
    font-weight: $font-weight-bold;
    font-size: 11px;
    font-family: $roboto;

    &.isActiveTab {
      opacity: 1;
    }
  }
}

$tab-icon-size: 24px;

.tabIcon {
  width: $tab-icon-size;
  height: $tab-icon-size;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.tabAnimation {
  width: $tab-icon-size;
  height: $tab-icon-size;
}

.category {
  composes: tabIcon;
  background-image: url('../../resources/category-tab.svg');
}

.article {
  composes: tabIcon;
  background-image: url('../../resources/discover-tab.svg');
}

.home {
  composes: tabIcon;
  background-image: url('../../resources/home-tab.svg');
}

.likes {
  composes: tabIcon;
  background-image: url('../../resources/likes-tab.svg');
}

.account {
  composes: tabIcon;
  background-image: url('../../resources/account-tab.svg');
}
