.oauthButton{
  cursor: pointer;
  border-radius: 9px;
  height: 48px;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 6px;

  &:last-child {
    margin-right: 0;
  }
}
