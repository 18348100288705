.modal {
  --height: 300px;
}
.title {
  text-align: center;
}
.button {
  width: 90%;
  margin: 20px auto;
  text-transform: uppercase;
}